import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const ColorButtonBorder = withStyles((theme) => ({
  root: {
    color: "#ffffff",
    height: "35px",
    padding: "6px 15px",
    fontSize: "1rem",
    whiteSpace: "nowrap",
    borderRadius: "0",
    border: "1px solid white",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    },
    "&:hover": {
      boxShadow: "1px 3px 15px 0px rgb(11 24 60 / 62%)"
    }
  }
}))(Button);

const ColorButton = withStyles((theme) => ({
  root: {
    // backgroundColor: "#DB6825",
    // backgroundColor: "rgb(245, 221, 10)",

    color: "#313131d9",
    height: "35px",
    padding: "6px 15px",
    fontSize: "1rem",
    whiteSpace: "nowrap",
    boxShadow: "2px 2px 1px 0px rgb(65 39 10 / 56%)",
    borderRadius: "0px",
    background: "#FDC830",
    background: "-webkit-linear-gradient(to right, #F37335, #FDC830)",
    background: "linear-gradient(to right, #F37335, #FDC830)",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
      // backgroundColor: "#000"
    },
    "&:hover": {
      background: "#FDC830",
      background: "-webkit-linear-gradient(to right, #FDC830, #FDC830)",
      background: "linear-gradient(to right, #FDC830, #FDC830)",
      boxShadow: "1px 3px 15px 0px rgb(197 179 89 / 62%)"
      // transition: "background 1s"
    }
  }
}))(Button);

export default function ButtonComponent({ title, className, styleButton = "fill", ...other }) {
  return (
    <>{
      styleButton === "fill" ?
        <ColorButton className={className} {...other}>
          {title}
        </ColorButton>
        :
        <ColorButtonBorder className={className} {...other}>
          {title}
        </ColorButtonBorder>
    }


    </>
  );
}
