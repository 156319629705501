import {config} from "./config";

export const dataServices = [
  {
    id: 0,
    url: "business_security", 
    name: "Охрана бизнеса",
    img2: "apartments2.svg",
    img: "apartments3.svg",
    connectionCost: "2990",
    subscriptionFee: "1200",
    description: `Посвящайте время важным делам и людям. Заботу о безопасности предоставьте компании ${config.NAME_PROJECT}. Наши передовые технологии в руках опытных сотрудников встанут на защиту вашего бизнеса.`
  },
  {
    id: 1,
    url:"apartment_security",
    name: "Охрана квартир",
    img2: "apartments2.svg",
    img: "apartments3.svg",
    connectionCost: "2990",
    subscriptionFee: "1200",
    description: `Ответственность за сохранность своей квартиры всегда лежит на её владельце. Разделите с нами этот груз! Специалисты компании ${config.NAME_PROJECT} защитят вашу собственность от нападения грабителей и квартирных краж.`
  },
  {
    id: 2,
    url:"schools_security",
    name: "Охрана школ",
    img2: "apartments2.svg",
    img: "apartments3.svg",
    connectionCost: "2990",
    subscriptionF: "1200",
    description: `ДЕТИ - самое ценное в нашей жизни! Но именно они больше других подвержены опасностям преступного мира. Доверьте заботу о безопасности подрастающего поколения профессионалам компании ${config.NAME_PROJECT}. Наши специалисты позаботятся о ваших детях как о своих.`
  },
  {
    id: 3,
    url: "home_security",
    name: "Охрана дома",
    img2: "apartments2.svg",
    img: "apartments3.svg",
    connectionCost: "2990",
    subscriptionFee: "1200",
    description: `Ваш ДОМ - ваша крепость. Защита вашего дома - наша главная задача. С компанией ${config.NAME_PROJECT} вы будете спокойны за целостность и сохранность вашей территории. Опыт наших профессионалов и передовые технологии защитят дом, и вы можете спать спокойно.`
  },
  {
    id: 4,
    url:"office_security",
    name: "Охрана офиса",
    img2: "apartments2.svg",
    img: "apartments3.svg",
    connectionCost: "2990",
    subscriptionFee: "1200",
    description: `Безопасность рабочей среды так же важна, как безопасность дома, ведь на работе мы проводим большую часть времени. Каждому сотруднику необходимо чувствовать себя защищённым, чтобы действовать эффективно. Позаботьтесь о себе и своём персонале, доверив безопасность профессионалам.`
  },
  {
    id: 5,
    url:"store_security",
    name: "Охрана магазина",
    img2: "apartments2.svg",
    img: "apartments3.svg",
    connectionCost: "2990",
    subscriptionF: "1200",
    description: `Создайте для своих сотрудников надёжную рабочую среду, а для клиентов безопасное пребывание в магазине. Комфорт и уверенность в своей защите позволят сотрудникам работать эффективнее, а покупателям выбирать лучшее.`
  },
  {
    id: 6,
    url:"warehouse_security",
    name: "Охрана склада",
    img2: "apartments2.svg",
    img: "apartments3.svg",
    connectionCost: "2990",
    subscriptionFee: "1200",
    description: `Неприкосновенность товаров и вещей - основная задача любого склада. Компания ${config.NAME_PROJECT} обеспечит их круглосуточную защиту от грабителей и хулиганов на протяжении всего времени хранения.`
  },
  {
    id: 7,
    url:"state_institutions_security",
    name: "Охрана гос.учреждения",
    img2: "apartments2.svg",
    img: "apartments3.svg",
    connectionCost: "2990",
    subscriptionFee: "1200",
    description: `Люди, обращающиеся в гос.учреждения, ждут быстрого и качественного решения своих вопросов. Профессионалы компании ${config.NAME_PROJECT} создадут безопасную среду для эффективного сотрудничества.`
  },
  {
    id: 8,
    url:"events_security",
    name: "Охрана мероприятий",
    img2: "apartments2.svg",
    img: "apartments3.svg",
    connectionCost: "2990",
    subscriptionF: "1200",
    description: `Места большого скопления людей нередко представляют опасность. Предоставьте заботы о защите присутствующих профессионалам, и ваше мероприятие пройдёт «без сучка и задоринки»!`
  },
  {
    id: 9,
    url:"shopping_centers_security",
    name: "Охрана торговых центров",
    img2: "apartments2.svg",
    img: "apartments3.svg",
    connectionCost: "2990",
    subscriptionF: "1200",
    description: `Обеспечьте себе и посетителям торгового центра безопасность и комфорт! Под нашей надёжной защитой покупатели и гости торговых площадей будут чувствовать себя спокойно, уверенно и обязательно вернутся к вам ещё!`
  }
];
