import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
    overflow: "auto",
    padding: "0px 30px 50px 30px",
    "&::-webkit-scrollbar": {
      height: "10px"
      // width: "30px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#fdc830",
      // borderRadius: "10px"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0",
      paddingBottom: "50px",
    }
  },
  gridBlockStyle: {
    background: "#ffffff",
    padding: "70px 0px 0px 0px",
    // marginBottom: "50px",
    // marginTop: "50px",
    [theme.breakpoints.down("xs")]: {
      padding: "70px 0px 0px 0px"
    }
  },
  title: {
    fontSize: "3rem",
    fontFamily: "Yeseva One, Segoe UI, sans-serif",
    paddingBottom: "30px",
    textAlign: "left",
    paddingLeft: "50px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "20px",
      fontSize: "2.5rem",
    }
  }
}));

export default function BlockContent({ title, children, className, classChildren, id }) {
  const classes = useStyles();
  return (
    <Grid id={id} className={clsx(classes.gridBlockStyle, className)}>
      <Typography className={classes.title}>{title}</Typography>
      <Grid container className={clsx(classes.gridList, classChildren)}>
        {children}
      </Grid>
    </Grid>
  );
}
