import React from "react";
import { config } from "../config";
import MainContent from "../components/mainContent";
import Feedback from "../components/wrapper/feedback";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import SvgIcon from "@material-ui/core/SvgIcon";
import clsx from "clsx";
import { ReactComponent as LogoIcon } from "./location.svg";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: "50px 0px 50px 0px"
  },
  title: {
    padding: "20px 0px 10px 0px"
  },
  cardContent: {
    // padding: "0rem 5rem 0rem 5rem"
  },
  blockText: {
    background: "rgba(6,48,92,0.16)",
    // margin: "20px 0px 20px 0px",
    padding: "50px",
    color: "white",
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      padding: "20px",
    }
  },
  blockText_number: {
    fontSize: "3rem",
    fontWeight: "bold"
  },
  blockText_description: {
    fontSize: "1rem"
  },
  blockContent: {
    width: "70%",
    margin: "0 auto"
  },
  buttonOrandge: {
    backgroundColor: "#DB6825",
    color: "#EBF0F5",
    fontSize: "12px"
  },
  svgStyle: {
    width: "100%",
    height: "100%",
    position: "relative",
    "&::before": {
      width: "81%",
      bottom: 0,
      height: "104%",
      content: '""',
      position: "absolute",
      background: "#06305c",
      borderRadius: "100% / 100% ",
      top: " 162%",
      left: "9%",
      filter: "blur(5px)"
    }
  },
  svgIcon: {
    fontSize: "18rem",
    color: "white",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10rem",
    }
  },
}));

export default function Contact() {
  const classes = useStyles();
  return (
    <>
      <MainContent>
        <div className={classes.blockText}>
          <Grid container direction="column">
            <Grid item>
              <p className={classes.blockText_number}>{config.CITY}</p>
            </Grid>
            <Grid item container direction="column">
              <Grid item>
                <Typography
                  variant="h5"
                  component="p"
                  className={classes.title}
                >
                  Адрес
                </Typography>
              </Grid>
              <Grid item>
                <p className={classes.blockText_description}>
                  {config.ADDRESS}
                </p>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h5" component="p" className={classes.title}>
                Контактные данные
              </Typography>
              <Grid item container>
                <Grid item xs={3}>
                  <p className={classes.blockText_description}>Телефон:</p>
                </Grid>
                <Grid item>
                  <p className={classes.blockText_description}>
                    {config.PHONE}
                  </p>
                </Grid>
              </Grid>
              <Grid item container>
                <Grid item xs={3}>
                  <p className={classes.blockText_description}>E-mail:</p>
                </Grid>
                <Grid item>
                  <p className={classes.blockText_description}>
                    {config.EMAIL}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <span className={classes.svgStyle}>
          <SvgIcon
            component={LogoIcon}
            viewBox="0 0 24 30"
            className={clsx("svgAnimation", classes.svgIcon)}
          />
        </span>
      </MainContent>
      <Feedback />
    </>
  );
}
