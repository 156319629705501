import React from "react";
import BlockContent from "../components/blockContent";
import { config } from "../config";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";



const useStyles = makeStyles((theme) => ({
  content: {
    padding: "0px 20px 0px 20px",
    textAlign: "left"
  },
  blockContent: {
    flexWrap: "wrap"
  },
  content__license: {
    padding: "25px"
  }
}))

export default function About() {
  const classes = useStyles();
  return (
    <BlockContent title="О нас" classChildren={classes.blockContent}>
      <Grid item xs={12} className={classes.content}>
        Защищаем клиентов с {config.DATE_OF_CREATION} года. Компания {config.NAME_PROJECT}, основана в {config.DATE_OF_CREATION} году. С
        первого дня работы мы не просто оказываем охранные услуги клиентам, но и
        тщательно исследуем технологии и принципы обеспечения безопасности.
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs className={classes.content__license}><img src="/license1.jpg" width="100%" /></Grid>
        <Grid item xs className={classes.content__license}><img src="/license2.jpg" width="100%" /></Grid>
      </Grid>
    </BlockContent>
  );
}
