import React, { useState } from "react";
import FormCallback from "../components/formCallback";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";


import { dataServices } from "../dataServices";



const useStyles = makeStyles((theme) => ({
  gridList: {
    // flexWrap: "nowrap",
    transform: "translateZ(0)"
    // overflow: "auto",
  },
  gridItemStyle: {
    color: "#313131d9",
    width: "150px",
    height: "150px",
    display: "flex",
    padding: "10px",
    fontSize: "1.5rem",
    boxShadow: "1px 6px 13px 0px rgb(40 40 40 / 9%), 1px 2px 1px 0px rgb(40 40 40 / 10%)",
    alignItems: "center",
    fontFamily: "Play, Segoe UI",
    borderRadius: "10%",
    justifyContent: "center",
    flexWrap: "wrap",
    "&:hover": {
      boxShadow: "0px 1px 4px 0px rgb(30 30 30 / 10%)"
      // transition: "background 1s"
    }
  },
  gridBlockStyle: {
    background: "#ffffff",
    padding: "70px 24px 70px 24px"
  },
  blockServices: {
    // width: "100px",
    padding: "20px"
  },
  blockServiceItemText: {
    fontSize: "1rem"
  },
  title: {
    fontSize: "3rem",
    fontFamily: "Yeseva One, Segoe UI, sans-serif",
    paddingBottom: "40px",
  },
  subtitle: {
    fontSize: "1rem",
    fontFamily: "Segoe UI, sans-serif",
    paddingBottom: "20px",
  },
  media: {
    backgroundSize: "contain",
    height: "70px",
    margin: "10px"
  },
  cardContent: {
    // width: "70%",
    margin: "0 auto"
  },
  blockText: {
    background: "rgba(6,48,92,0.16)",
    // margin: "20px 0px 20px 0px",
    padding: "50px",
    color: "white",
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      padding: "20px",
    }
  },
  blockText_title: {
    fontSize: "3rem",
    fontWeight: "bold",
    // marginBottom: "20px"
  },
  blockText_description: {
    fontSize: "1rem",
    margin: "30px auto"
  },
  blockContent: {
    width: "70%",
    margin: "0 auto"
  },
  buttonOrandge: {
    backgroundColor: "#DB6825",
    color: "#EBF0F5",
    fontSize: "12px"
  },
  svgStyle: {
    width: "100%",
    height: "200px",
    position: "relative",
    background: "url('../apartments.png') no-repeat center",
    // backgroundImage: "url(../apartments.png)",
    backgroundSize: "contain",
    // "&::before": {
    //   width: "81%",
    //   bottom: 0,
    //   height: "104%",
    //   content: '""',
    //   position: "absolute",
    //   background: "#06305c",
    //   borderRadius: "100% / 100% ",
    //   top: " 162%",
    //   left: "9%",
    //   filter: "blur(5px)"
    // }
  },
  gridItemStyle__price: {
    fontFamily: "Yeseva One, Segoe UI, sans-serif",
  }
}));

export default function Price() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [nameService, setNameService] = useState();

  const handleOpen = (name) => {
    setNameService(name)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <FormCallback options={nameService} handleClose={handleClose} />
      </Modal>
      <Grid className={classes.gridBlockStyle}>
        <Typography className={classes.title}>Стоимость услуг</Typography>
        <Typography className={classes.subtitle}>Цены носят информационный характер, для каждого объекта / клиента расчитывается индивидуально, отправьте заявку на расчет.</Typography>
        <Grid container justify="center" className={classes.gridList}>
          {dataServices.map((el, id) => (

            <Grid className={classes.blockServices} key={el.id} onClick={() => handleOpen(el.name)}>
              <div className={classes.gridItemStyle}>
                <Grid item xs={12}>{el.name}</Grid>
                <Grid item xs={12} className={classes.gridItemStyle__price}>от 100 ₽</Grid>
              </div>
            </Grid>

          ))}
        </Grid>
      </Grid >
    </>
  );
}