import React from "react";
import {config} from "../../config";
import FormCallback from "../formCallback";
import ButtonComponent from "../buttonComponent";
import clsx from "clsx";
import {Link } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { ReactComponent as LogoIcon } from "./logo.svg";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import PhoneIcon from "@material-ui/icons/Phone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";

const useStyles = makeStyles((theme) => ({
 
  menuButton: {},
  title: {
    margin: "0px 20px 0px 7px"
  },
  menuStyle: {
    display: "flex",
    width: "100%",
    outline: "none",
    flexDirection: "column",
    alignItems: "center",
  },

  blockNavigation: {
    height:"100%",
    background: "#06305C",
    color: "#EBF0F5",
    paddingRight: "16px",
  
  },
  blockMenuMobile: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block"
    }
  },
  menuItem__size: {
      fontSize: "2rem"
  }
}));

export default function NavMobile() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
          <Grid container justify="space-between" alignContent="center" className={classes.blockNavigation}>
            <Grid item xs={12}>
              <MenuList className={classes.menuStyle} onClick={handleClose}>
                <MenuItem className={classes.menuItem__size}>
                  <Link to="/services">Услуги</Link>
                </MenuItem>
                <MenuItem className={classes.menuItem__size}>
                  <Link to="/price">Цены</Link>
                </MenuItem>
                {/* <MenuItem>
                  <Link to="/sale">Акции</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/news">Новости</Link>
                </MenuItem> */}
                <MenuItem className={classes.menuItem__size}>
                  <Link to="/contact">Контакты</Link>
                </MenuItem>
                <MenuItem className={classes.menuItem__size}>
                  <Link to="/about">О компании</Link>
                </MenuItem>
              </MenuList>
            </Grid>
            {/* <Grid item  xs={12} >
              <LocationOnOutlinedIcon />
              {config.CITY}
            </Grid> */}
          </Grid>
      </Modal>
    
    <Grid className={classes.blockMenuMobile}>
        <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={handleOpen}
        >
            <MenuIcon />
        </IconButton>
    </Grid>
             
           
    </>
  );
}
