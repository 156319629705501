export const config = {
    NAME_PROJECT: `"Пугачев"`,
    NAME_PROJECT2: `Пугачев`,
    FORM: "Частная охранная организация",
    FORM_REDUCTION: "ЧОО",
    INN: "5610219995",
    OGRN: "1165658062250",
    INDEX: "460048",
    REGION: "Оренбургская обл",
    CITY:"Оренбург",
    ADDRESS: "г. Оренбург, ул. Салмышская, дом №72, офис 18",
    PHONE: "+7 (903) 360-26-96",
    EMAIL: "iceberg_oren56@mail.ru",
    DATE_OF_CREATION: "2016"
}