import React from "react";
import BlockContent from "../blockContent";
import { dataServices } from "../../dataServices";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";

const useStyles = makeStyles((theme) => ({
  gridItemStyle: {
    color: "#313131d9",
    width: "150px",
    height: "150px",
    display: "flex",
    padding: "10px",
    fontSize: "1.5rem",
    boxShadow: "1px 6px 13px 0px rgb(40 40 40 / 9%), 1px 2px 1px 0px rgb(40 40 40 / 10%)",
    alignItems: "center",
    fontFamily: "Play, Segoe UI",
    borderRadius: "10%",
    justifyContent: "center",
    "&:hover": {
      boxShadow: "0px 1px 4px 0px rgb(30 30 30 / 10%)"
      // transition: "background 1s"
    }
  },
  blockServices: {
    padding: "20px"
  },
  blockServiceItemText: {
    fontSize: "1rem",
    padding: "15px"
  },
  media: {
    backgroundSize: "contain",
    height: "100px",
    margin: "10px"
  }
}));

export default function Services() {
  const classes = useStyles();
  return (
    <BlockContent title="Услуги">
      {dataServices.map((el) => (
        <Grid className={classes.blockServices} key={el.id}>
          <Link to={`/services/${el.url}`} style={{ color: "black" }}>
            <div className={classes.gridItemStyle}>
              {el.name}
            </div>

          </Link>
        </Grid>
      ))}
    </BlockContent>
  );
}
