import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ScrollToTop from "../lib/scrolTop";
import Services from "../components/wrapper/services";
import Feedback from "../components/wrapper/feedback";
import ButtonComponent from "../components/buttonComponent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { dataServices } from "../dataServices";
import { Route, Switch, Link } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  gridList: {
    // flexWrap: "nowrap",
    transform: "translateZ(0)"
    // overflow: "auto",
  },
  gridItemStyle: {
    color: "#313131d9",
    width: "150px",
    height: "150px",
    display: "flex",
    padding: "10px",
    fontSize: "1.5rem",
    boxShadow: "1px 6px 13px 0px rgb(40 40 40 / 9%), 1px 2px 1px 0px rgb(40 40 40 / 10%)",
    alignItems: "center",
    fontFamily: "Play, Segoe UI",
    borderRadius: "10%",
    justifyContent: "center",
    "&:hover": {
      boxShadow: "0px 1px 4px 0px rgb(30 30 30 / 10%)"
      // transition: "background 1s"
    }
  },
  gridBlockStyle: {
    background: "#ffffff",
    padding: "70px 24px 70px 24px"
  },
  blockServices: {
    // width: "100px",
    padding: "20px"
  },
  blockServiceItemText: {
    fontSize: "1rem"
  },
  title: {
    fontSize: "3rem",
    fontFamily: "Yeseva One, Segoe UI, sans-serif",
    paddingBottom: "40px",
  },
  media: {
    backgroundSize: "contain",
    height: "70px",
    margin: "10px"
  },
  cardContent: {
    // width: "70%",
    cursor: "pointer",
    margin: "0 auto"
  },
  blockText: {
    background: "rgba(6,48,92,0.16)",
    // margin: "20px 0px 20px 0px",
    padding: "50px",
    color: "white",
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      padding: "20px",
    }
  },
  blockText_title: {
    fontSize: "3rem",
    fontWeight: "bold",
    // marginBottom: "20px"
  },
  blockText_description: {
    fontSize: "1rem",
    margin: "30px auto"
  },
  blockContent: {
    width: "70%",
    margin: "0 auto"
  },
  buttonOrandge: {
    backgroundColor: "#DB6825",
    color: "#EBF0F5",
    fontSize: "12px"
  },
  svgStyle: {
    width: "100%",
    height: "200px",
    position: "relative",
    background: "url('../apartments.png') no-repeat center",
    // backgroundImage: "url(../apartments.png)",
    backgroundSize: "contain",
    // "&::before": {
    //   width: "81%",
    //   bottom: 0,
    //   height: "104%",
    //   content: '""',
    //   position: "absolute",
    //   background: "#06305c",
    //   borderRadius: "100% / 100% ",
    //   top: " 162%",
    //   left: "9%",
    //   filter: "blur(5px)"
    // }
  }
}));

export default function ServicesPage() {
  return (
    <Switch>
      <Route exact path="/services" component={FullServices} />
      <Route path="/services/:url" component={ServiceItem} />
    </Switch>
  );
}
const ServiceItem = (props) => {
  const classes = useStyles();

  function get(url) {
    const isItem = (p) => p.url === url;
    return dataServices.find(isItem);
  }
  const item = get(props.match.params.url);
  if (!item) {
    return <div>Sorry, but the player was not found</div>;
  }
  return (
    <>
      <ScrollToTop />
      <div className={classes.cardContent}>
        <div className={classes.blockText}>
          <h1 className={classes.blockText_title}>{item.name}</h1>
          <p className={classes.blockText_description}>
            {item.description}
          </p>
          <a href="#form"><ButtonComponent title="Оставить заявку" /></a>
        </div>
      </div>
      <Services />
      <Feedback />
    </>
  );
};
const FullServices = () => {
  const classes = useStyles();
  return (
    <Grid className={classes.gridBlockStyle}>
      <Typography className={classes.title}>Услуги</Typography>
      <Grid container justify="center" className={classes.gridList}>
        {dataServices.map((el, id) => (
          <Grid className={classes.blockServices} key={el.id}>
            <Link to={`/services/${el.url}`} style={{ color: "black" }}>
              <div className={classes.gridItemStyle}>
                {el.name}
              </div>

            </Link>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
